import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import Login from '../views/Login.vue'
import index from '../views/index.vue'
import pager from '../views/pager.vue'
import create from '../views/create.vue'
import forget from '../views/forget.vue'
import pastpaper from '../views/pastpaper.vue'
import question from '../views/question.vue'
import answer from '../views/answer.vue'
import examinfo from '../views/examinfo.vue'
import pagerinfo from '../views/pagerinfo.vue'
import report from '../views/report.vue'
import makereport from '../views/makereport.vue'
import submitscore from '../views/submitscore.vue'
import centerindex from '../views/center/index.vue'
import centeragree from '../views/center/agree.vue'
import centerprivacy from '../views/center/privacy.vue'

import VueCookies from 'vue-cookies'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/forget',
    name: 'forget',
    component: forget
  },
  {
    path: '/',
    component: Layout,
    hidden: true,
    children: [
		{
			path: '/',
			name:"index",
			component: index,
			meta:{nav:1}
		},
		{
			path: '/create',
			name:"create",
			component: create,
			meta:{nav:1}
		},
		{
			path: '/pastpaper',
			name:"pastpaper",
			component: pastpaper,
			meta:{nav:1}
		},
		{
			path: '/question',
			name:"question",
			component: question,
			meta:{nav:1}
		},
		{
			path: '/answer',
			name:"answer",
			component: answer,
			meta:{nav:1}
		},
		{
			path: '/examinfo',
			name:"examinfo",
			component: examinfo,
			meta:{nav:1}
		},
		{
			path: '/pagerinfo',
			name:"pagerinfo",
			component: pagerinfo,
			meta:{nav:1}
		},
		{
			path: '/report',
			name:"report",
			component: report,
			meta:{nav:1}
		},
		{
			path: '/makereport',
			name:"makereport",
			component: makereport,
			meta:{nav:1}
		},
		{
			path: '/submitscore',
			name:"submitscore",
			component: submitscore,
			meta:{nav:1}
		},
		
		{
			path: '/pager',
			name:"pager",
			component: pager,
			meta:{nav:1}
		},
		{
			path: '/center',
			name:"centerindex",
			component: centerindex,
			meta:{nav:2}
		},
		{
			path: '/center/agree',
			name:"centeragree",
			component: centeragree,
			meta:{nav:2}
		},
		{
			path: '/center/privacy',
			name:"centerprivacy",
			component: centerprivacy,
			meta:{nav:2}
		},
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




router.beforeEach(async (to, from, next) => {
	if(to.name == "login" || to.name == "forget"){
		next();
	}else{
		const token = VueCookies.get('teacheruserToken');
		if (token && token !== 'undefined') {
			next()
		} else {
			// 没有登录的时候跳转到登录界面
			// 携带上登陆成功之后需要跳转的页面完整路径
			next({
				name: 'login'
			})
		}
	}
})

export default router
