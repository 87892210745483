<template>
	<div>
		<div class="result">
			<img class="result-close" @click="tolast" src="@/assets/images/close.png"/>
			<div class="result-title">{{title}}</div>
			<div class="result-top">
				<div class="result-attr flex-start flex-center flex-wrap">
					<div><span>考卷人</span>{{pagerInfo.answerUserName}}</div>
					<div><span>考卷人电话</span>{{pagerInfo.mobile}}</div>
					<div><span>难度</span>{{pagerInfo.difficultyName}}</div>
					<div><span>学生 考卷时间</span>{{pagerInfo.answerTime}}</div>
					<div><span>知识点评语</span></div>
				</div>
				<textarea placeholder="请输入知识评语" v-model="notes"></textarea>
				<div class="result-button flex-start flex-center">
					<div class="result-button-one" @click="download">下载试卷</div>
					<el-upload :action="upload" name="file" :show-file-list="false" :on-success="imageSuccess" style="text-align: center;">
						<div class="result-button-one">上传批改</div>
					</el-upload>
					<div class="result-button-two">制作报告<span>打分后制作</span></div>
					<div class="result-button-two" @click="submitPanjuan">提交</div>
				</div>
			</div>
			
			<div class="result-list">
				<div class="result-list-item" v-for="(item, index) in list" :key="item.id">
					<img class="result-list-item-title" :src="item.imageUrl"/>
					<div class="result-list-item-score">分值：{{item.score}}</div>
					
					<!-- 选择题的答案 -->
					<div class="result-list-item-answer flex-start flex-center" v-if="item.questionType == 1">
						<div class="flex-start flex-center">提交答案 <span>{{item.userAnswer}}</span>
						</div>
						<div>正确答案 <span>{{item.answer}}</span></div>
					</div>
					<!-- 简答题的答案 -->
					<div v-else>
						<div class="result-list-item-info flex-start" v-if="item.userAnswer">
							<div>学生答案</div>
							<div class="result-list-item-info-content">{{item.userAnswer}}</div>
						</div>
						<div class="result-list-item-info flex-start" v-if="item.fileUrl.length > 0">
							<div>学生答案</div>
							<div class="result-list-item-list flex-start flex-center">
								<el-image v-for="(value, key) in item.fileUrl" :src="imageurl+value" fit="contain" :preview-src-list="[imageurl+value]"></el-image>
								<!-- <img v-for="(value, key) in item.fileUrl" :src="imageurl+value"/> -->
							</div>
						</div>
					</div>
					
					<div v-if="item.questionType != 1" class="flex-between flex-center">
						<div class="panjuan flex-start flex-center">
							<div>打分</div>
							<el-input class="panjuan-input" type="number" placeholder="请输入分数" v-model="item.answerScore"></el-input>
						</div>
					</div>
				</div>
				
				
			</div>
		</div>
		<downPdf ref="downPdfRef" @ok="downloadSubmit"></downPdf>
	</div>
</template>

<script>
	
	import {getQuestionListByPaper,getNewPaperAnswers,paperAnswerMarkNotes,paperDownload} from "@/api/index"
	import {uploadUrl,imageurl} from "@/api/config"
	import downPdf from "@/components/downPdf"
	export default {
		components:{
			downPdf
		},
		data(){
			return {
				id:0,
				list:[],
				title:"",
				selectNum:0,
				submitStatus:false,
				downStatus:false,
				upload:"",
				imageurl:"",
				pagerInfo:{},
				panjuan:false,//是否开始判卷
				submitLoading:false,
				answerId:0,
				notes:"",
				noteFile:""
				
			}
		},
		mounted() {
			this.upload = uploadUrl();
			this.imageurl = imageurl();
			this.id = this.$route.query.id || 0;
			this.answerId = this.$route.query.answerId || 0;
			getNewPaperAnswers({
				paperId:this.id,
				answersId:this.answerId
			}).then(res=>{
				if(res.code == 0){
					this.pagerInfo = res.data;
					
				}else{
					// this.$router.replace("/");
				}
			})
			this.getInfo();
		},
		methods:{
			getInfo(){
				getQuestionListByPaper({
					paperId:this.id,
					password:"",
					answersId:this.answerId
				}).then(res=>{
					if(res.code == 0){
						res.data.map(item=>{
							item.fileUrl = item.fileUrl?item.fileUrl:[];
						})
						this.list = res.data;
						this.title = res.name;
						this.selectNum = res.singleAnswerNum;
					}else{
						// this.$router.replace("/");
					}
				})
			},
			tolast(){
				this.$router.go(-1); 
			},
			//提交自动判卷
			submitPanjuan(){
				let data = [];
				let submit = true;
				this.list.map(item=>{
					if(item.questionType != 1){
						if(item.answerScore === null || item.score < item.answerScore){
							submit = false;
						}
						data.push({
							newPaperId:item.newPaperId,
							answerId:this.answerId,
							questionId:item.questionId,
							score:item.answerScore
						});
					}else{
						data.push({
							newPaperId:item.newPaperId,
							answerId:this.answerId,
							questionId:item.questionId,
							score:0
						});
					}
					
				})
				if(!submit){
					return this.$message.error("有题目未评分或超出分值");
				}
				paperAnswerMarkNotes({
					newPaperId:this.id,
					id:this.pagerInfo.id,
					status:1,
					questionJson:data,
					notes:this.notes,
					notesUserId:Number(this.$cookies.get('teacheruserId')),
					noteFile:this.noteFile 
				}).then(res=>{
					if(res.code == 0){
						this.$message.success("提交成功");
						this.$router.go(-1);
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//下载试卷
			download(){
				this.$refs.downPdfRef.init({id:this.id});
				
			},
			downloadSubmit(e){
				paperDownload({id:e.id,type:e.type}).then(res=>{
					const url = window.URL.createObjectURL(new Blob([res]))
					const link = document.createElement("a")
					link.href = url
					if(e.type == 1){
						link.setAttribute("download",'教师版.pdf')//导出的格式一定要和接口返回的文件一致
					}else{
						link.setAttribute("download",'学生版.pdf')//导出的格式一定要和接口返回的文件一致
					}
					
					document.body.appendChild(link)
					link.click()
					window.URL.revokeObjectURL(url)
					document.body.removeChild(link)
				})
			},
			//上传观测点的图片成功
			imageSuccess(info,e){
				if(e.response.code == 0){
					this.$message.success("上传成功");
					this.noteFile = e.response.fileName;
				}else{
					this.$message.error("上传失败");
				}
			},
		}
	}
</script>


<style lang="scss" scoped>
	@import "../assets/examinfo.scss";
	textarea{
		width: 944px;
		height: 108px;
		background: #F6F6F6;
		border-radius: 8px;
		padding: 10px;
	}
	.result-button-two{
		position: relative;
		span{
			position: absolute;
			width: 74px;
			height: 20px;
			background: rgba(255, 31, 31, 0.1);
			border-radius: 8px 100px 100px 0px;
			font-weight: 400;
			font-size: 12px;
			color: #FF1F1F;
			line-height: 20px;
			text-align: center;
			top: -25px;
		}
	}
</style>