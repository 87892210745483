<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="270px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="true" :close-on-click-modal="false">
	 <div class="msg">
		 <div class="msg-title">温馨提示</div>
		 <div class="wechatcode-desc flex-center flex-midden">
			 <div @click="type = 1">
				 <img v-if="type == 1" src="@/assets/images/select.png"/>
				 <img v-else src="@/assets/images/noselect.png"/>
				 教师版
			 </div>
			 <div @click="type = 2">
				 <img v-if="type == 2" src="@/assets/images/select.png"/>
				 <img v-else src="@/assets/images/noselect.png"/>
				 学生版
			 </div>
		 </div>
	 </div>
	  <span slot="footer" class="dialog-footer flex-midden flex-center">
		  <div class="dialog-footer-two" @click="submit">确定</div>
	  </span>
	</el-dialog>
</template>

<script>
	export default {
		data(){
			return {
				dialogVisible:false,
				value:{},
				type:1
			}
		},
		methods:{
			init(value){
				this.value = value;
				this.type = 1;
				this.dialogVisible = true;
			},
			submit(){
				this.dialogVisible = false;
				this.$emit("ok", {...this.value, type:this.type});
			}
		}
	}
</script>


<style scoped lang="scss">
	.msg{
		.msg-title{
			font-weight: bold;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			text-align: center;
			padding: 0px 0px 11px;
		}
		.wechatcode-desc{
			padding: 10px 0px;
			div{
				font-weight: 400;
				font-size: 14px;
				color: #777C85;
				line-height: 20px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-right: 20px;
				cursor: pointer;
				img{
					width: 16px;
					height: 16px;
					margin-right: 5px;
				}
			}
		}
	}
	.dialog-footer{
		.dialog-footer-two{
			width: 166px;
			height: 34px;
			border: 1px solid #FF8D1F;
			background: #FF8D1F;
			border-radius: 6px;
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 34px;
			text-align: center;
			margin: 0px 10px;
			cursor: pointer;
		}
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
	
	::v-deep .el-input__inner{
		width: 370px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		font-weight: 400;
		font-size: 16px;
		color: #D1D2D4;
		line-height: 50px;
		text-align: left;
		border: 0px;
		margin: 0 auto;
		display: block;
	}
</style>