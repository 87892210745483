<template>
	<div>
		<div class="result">
			<img class="result-close" @click="tolast" src="@/assets/images/close.png"/>
			<div class="result-title">{{title}}</div>
			<div class="result-top">
				<div class="result-attr flex-start flex-center flex-wrap">
					<div><span>考卷人</span>{{pagerInfo.answerUserName}}</div>
					<div><span>考卷人电话</span>{{pagerInfo.mobile}}</div>
					<div><span>难度</span>{{pagerInfo.difficultyName}}</div>
					<div><span>学生 考卷时间</span>{{pagerInfo.answerTime}}</div>
					<div v-if="pagerInfo.handleAutomatic != 1 && pagerInfo.status < 4 && pagerInfo.notesUserName"><span>判卷老师</span>{{pagerInfo.notesUserName}}</div>
					<div v-if="pagerInfo.handleAutomatic != 1 && pagerInfo.status < 4 && pagerInfo.notes"><span>知识点评语</span>{{pagerInfo.notes}}</div>
				</div>
				<div class="result-score" v-if="pagerInfo.status < 4">试卷报告（{{pagerInfo.percentage}}     {{pagerInfo.answerScore}}分/{{pagerInfo.paperScore}}分）</div>
				<div class="result-num" v-if="pagerInfo.status < 4">
					<span v-for="item in pagerInfo.questions">{{item.name}}（{{item.answerScore}}分/{{item.questionScore}}分）</span>
				</div>
				<div v-if="pagerInfo.status == 4" class="result-button flex-start flex-center">
					<div class="result-button-two" @click="tolast">返回列表</div>
				</div>
				<div v-else-if="pagerInfo.status == 5" class="result-button flex-start flex-center">
					<div class="result-button-two" @click="tolast">返回列表</div>
				</div>
				<template v-else>
					<div v-if="pagerInfo.handleAutomatic == 1" class="result-button flex-start flex-center">
						<div @click="tolast" class="result-button-one">返回列表</div>
						<div v-if="pagerInfo.status == 1 && pagerInfo.noteFile" @click="seeUrl(pagerInfo.noteFile)" class="result-button-one">批改查阅/下载</div>
						<div class="result-button-two" @click="makeReport" v-if="pagerInfo.reportStatus == 0 && pagerInfo.questionType == 2">制作报告</div>
						<div class="result-button-two" @click="seeReport" v-if="pagerInfo.reportStatus == 1">查看报告</div>
					</div>
					<div v-else class="result-button flex-start flex-center">
						<div v-if="pagerInfo.status == 1 && pagerInfo.noteFile" @click="seeUrl(pagerInfo.noteFile)" class="result-button-one">批改查阅/下载</div>
						<div class="result-button-two" @click="seeReport" v-if="pagerInfo.reportStatus == 1">查看报告</div>
						<div class="result-button-two" @click="makeReport" v-if="pagerInfo.reportStatus == 0 && pagerInfo.questionType == 2">制作报告</div>
						<div class="result-button-two" @click="tolast">返回列表</div>
					</div>
				</template>
			</div>
			
			<div class="result-list">
				<div class="result-list-item" v-for="(item, index) in list" :key="item.id">
					<img class="result-list-item-title" :src="item.imageUrl"/>
					<div class="result-list-item-score">分值：{{item.score}}</div>
					
					<!-- 选择题的答案 -->
					<div class="result-list-item-answer flex-start flex-center" v-if="item.questionType == 1">
						<div class="flex-start flex-center">提交答案 <span>{{item.userAnswer}}</span>
						</div>
						<div>正确答案 <span>{{item.answer}}</span></div>
					</div>
					<!-- 简答题的答案 -->
					<div v-else>
						<div class="result-list-item-info flex-start" v-if="item.userAnswer">
							<div>学生答案</div>
							<div class="result-list-item-info-content">{{item.userAnswer}}</div>
						</div>
						<div class="result-list-item-info flex-start" v-if="item.fileUrl.length > 0">
							<div>学生答案</div>
							<div class="result-list-item-list flex-start flex-center">
								<el-image v-for="(value, key) in item.fileUrl" :src="imageurl+value" fit="contain" :preview-src-list="[imageurl+value]"></el-image>
								<!-- <img v-for="(value, key) in item.fileUrl" :src="imageurl+value"/> -->
							</div>
						</div>
					</div>
					
					<div class="flex-between flex-center">
						<div v-if="pagerInfo.status == 1" class="result-list-item-info flex-start">
							<div>该题得分</div>
							<div class="result-list-item-info-score">{{item.answerScore}}分</div>
						</div>
					</div>
				</div>
				
				
			</div>
		</div>
	</div>
</template>

<script>
	
	import {getQuestionListByPaper,getNewPaperAnswers} from "@/api/index"
	import {uploadUrl,imageurl} from "@/api/config"
	export default {
		data(){
			return {
				id:0,
				list:[],
				title:"",
				selectNum:0,
				submitStatus:false,
				downStatus:false,
				upload:"",
				imageurl:"",
				pagerInfo:{},
				panjuan:false,//是否开始判卷
				submitLoading:false,
				answerId:0
				
			}
		},
		mounted() {
			this.upload = uploadUrl();
			this.imageurl = imageurl();
			this.id = this.$route.query.id || 0;
			this.answerId = this.$route.query.answerId || 0;
			getNewPaperAnswers({
				paperId:this.id,
				answersId:this.answerId
			}).then(res=>{
				if(res.code == 0){
					if(res.data.status && res.data.status != 0 && res.data.status != 2){
						this.pagerInfo = res.data;
						// handleAutomatic  判卷方式1，自动，2.手动
					}else{
						// this.$router.replace("/");
					}
					
				}else{
					// this.$router.replace("/");
				}
			})
			this.getInfo();
		},
		methods:{
			getInfo(){
				getQuestionListByPaper({
					paperId:this.id,
					password:"",
					answersId:this.answerId
				}).then(res=>{
					if(res.code == 0){
						res.data.map(item=>{
							item.fileUrl = item.fileUrl?item.fileUrl:[];
						})
						this.list = res.data;
						this.title = res.name;
						this.selectNum = res.singleAnswerNum;
					}else{
						// this.$router.replace("/");
					}
				})
			},
			tolast(){
				this.$router.go(-1); 
			},
			seeReport(){
				this.$router.push("/report?id="+this.id+"&answerId="+this.answerId);
			},
			makeReport(){
				this.$router.push("/makereport?id="+this.id+"&answerId="+this.answerId);
			},
			seeUrl(url){
				window.open(url);
			},
		}
	}
</script>


<style lang="scss" scoped>
	@import "../assets/examinfo.scss";
</style>